<template>
  <div v-if="loading">
    <div class="tech-req__left_params_title">
      {{ tech.r_all_r[language] }}
    </div>
    <div class="tech-req__card">
      <div>
        <div
          v-if="allRoughnessColor"
          :style="{
              backgroundColor: `rgba(${allRoughnessColor.r}, ${allRoughnessColor.g}, ${
                allRoughnessColor.b
              })`
            }"
          class="tech-req__card_color">
        </div>
      </div>
      <div class="tech-req__card_container">
        <div class="tech-req__card_title block block__between">
          <div>{{ tech.r_add_all_r[language] }}</div>
          <div>
            <Info @click="openModal('showInfoAllModal')" />
          </div>
        </div>
        <div class="block block__between block__align-center" v-if="allRoughnessEdit">
          <div class="block__flex">
            <div class="block block__align-center" :style="{maxWidth: '100px'}">
              <Select type="select"
                      name="material_gost1"
                      design="contrast"
                      :defaultValue="capitalizeFirstLetter(activeRoughnessType)"
                      :values="[
                {
                  title: 'Ra',
                },
                {
                  title: 'Rz',
                }
              ]"
                      @change="setRoughnessType($event)"
                      :title="'title'"
                      class="tech-req__card_select" />
            </div>
            <div class="block" :style="{maxWidth: '100px', marginLeft: '10px'}">
              <Select type="select"
                      v-show="this.activeRoughnessType === 'ra'"
                      name="roughness_type"
                      :values="this.raValues"
                      design="contrast"
                      :defaultValue="allRaRoughnessValue"
                      :title="'short_name'"
                      :style="{width: '100%'}"
                      @change="setAllRoughnessValue($event, 'ra')"
                      class="tech-req__card_select" />
              <Select type="select"
                      v-show="this.activeRoughnessType === 'rz'"
                      name="roughness_type"
                      :values="this.rzValues"
                      design="contrast"
                      :defaultValue="allRzRoughnessValue"
                      @change="setAllRoughnessValue($event, 'rz')"
                      :title="'short_name'"
                      :style="{width: '100%'}"
                      class="tech-req__card_select" />
            </div>
          </div>
          <a-button size="small" type="primary" @click="saveAllRoughness">
            {{ tech.r_save[language] }}
          </a-button>
        </div>
        <div v-if="!allRoughnessEdit" class="block block__between block__align-center">
          <div class="tech-req__card_notactive_value" @click="() => {
          this.allRoughnessEdit = true;
        }">
            {{ `${capitalizeFirstLetter(activeRoughnessType)} ${
            activeRoughnessType === 'ra' ? allRaRoughnessValue : allRzRoughnessValue}` }}
          </div>
          <div class="tech-req__card_notactive_change" @click="() => {
          this.allRoughnessEdit = true;
        }">
            {{ tech.r_change[language] }}
          </div>
        </div>
      </div>
    </div>
    <div class="tech-req__left_params_title">
      {{ tech.r_surface_r[language] }}
    </div>
    <div v-for="item in groups" v-bind:key="item.id">
      <div :class="`tech-req__card ${
        String(activeIndex[activeIndex.length - 1]) === item.id ? 'tech-req__card_active' : ''
      }`">
        <div>
          <div
            v-if="groups.color"
            :style="{
              backgroundColor: `rgba(${item.color.r}, ${item.color.g}, ${item.color.b})`
            }"
            class="tech-req__card_color">
          </div>
        </div>
        <div class="tech-req__card_container">
          <div class="tech-req__card_title block block__between">
            <div class="block__flex block__align-bottom">
              <div>{{ tech.r_surface_count[language] }}</div>
              <div class="tech-req__card_count">
                {{item.length}}
              </div>
            </div>
            <div class="block__flex">
              <Info @click="openModal('showInfoModal')" />
              <Close @click="openDeleteModal(item.id)" :style="{marginLeft: '7px'}" />
            </div>
          </div>
          <div class="block block__between block__align-center"
               v-if="item.edit">
            <div class="block__flex">
              <div class="block" :style="{maxWidth: '100px'}">
                <Select type="select"
                        name="material_gost1"
                        design="contrast"
                        @change="setSurfaceRoughnessType($event, item.id)"
                        :defaultValue="activeRoughnessType === 'ra' ? 'Ra' : 'Rz'"
                        :values="[
                {
                  title: 'Ra',
                },
                {
                  title: 'Rz',
                }
              ]"
                        :title="'title'"
                        class="tech-req__card_select" />
              </div>
              <div class="block" :style="{maxWidth: '100px', marginLeft: '10px'}">
                <Select type="select"
                        v-show="item.type === 'ra'"
                        name="roughness_type"
                        design="contrast"
                        :values="raValues"
                        :title="'short_name'"
                        :defaultValue="item.value"
                        :disabled="true"
                        @change="setSurfaceRoughness($event, item.id)"
                        :style="{width: '100%'}"
                        class="tech-req__card_select" />
                <Select type="select"
                        v-show="item.type === 'rz'"
                        :disabled="true"
                        name="roughness_type"
                        design="contrast"
                        :values="rzValues"
                        :title="'short_name'"
                        :defaultValue="item.value"
                        @change="setSurfaceRoughness($event, item.id)"
                        :style="{width: '100%'}"
                        class="tech-req__card_select" />
              </div>
            </div>
            <a-button size="small" @click="saveRoughness(item.id)" type="primary"
                      :disabled="!item.value">
              {{ tech.r_save[language] }}
            </a-button>
          </div>
          <div v-if="!item.edit" class="block block__between block__align-center">
            <div class="tech-req__card_notactive_value"
                 v-if="item.value"
                 @click="changeRoughnessGroup(item.id)">
              {{ `${capitalizeFirstLetter(item.type)} ${item.value}` }}
            </div>
            <div class="tech-req__card_notactive_change"
                 v-if="!item.value"
                 @click="changeRoughnessGroup(item.id)">
              {{ tech.r_r_not_set[language] }}
            </div>
            <div class="tech-req__card_notactive_change" @click="changeRoughnessGroup(item.id)">
              {{ tech.r_change[language] }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class="`tech-req__card tech-req__card_plus`" v-if="!allRoughnessEdit">
      <div v-if="activeAdd" @click="addRoughness">
        <div class="block block__center">
          <Plus />
        </div>
        <div class="block block__center">
          <div class="tech-req__card_plus_text">
            {{ tech.r_surface_add[language] }}
          </div>
        </div>
      </div>
      <div v-if="!activeAdd">
        <div class="block block__center block__column block__align-center">
          <div class="tech-req__card_plus_text">
            {{ tech.r_surface_select[language] }}
          </div>
          <div class="tech-req__card_plus_text tech-req__card_plus_cancel"
               @click="cancelAddRoughness">
            {{ tech.r_cancel[language] }}
          </div>
        </div>
      </div>
    </div>
    <div :class="`tech-req__card`" v-if="allRoughnessEdit">
      <div class="block block__center block__column block__align-center">
        <div class="tech-req__card_plus_text">
          {{ tech.r_for_add_surface[language] }}
        </div>
      </div>
    </div>
    <Modal :title="{
      ru: 'Добавление шероховатости для всей детали',
      en: 'Add roughness to the entire part',
    }" :show="showInfoAllModal" @ok="closeModal('showInfoAllModal')" :okText="{
      ru: 'Ок',
      en: 'OK',
    }" :showCancel="false" @cancel="closeModal('showInfoAllModal')">
      <template slot="body">
        <div>{{ tech.all_info_modal[language] }}</div>
      </template>
    </Modal>
    <Modal :title="{
      ru: 'Добавление шероховатости для выбранных поверхностей',
      en: 'Добавление шероховатости для выбранных поверхностей',
    }" :show="showInfoModal"  @ok="closeModal('showInfoModal')" :okText="{
      ru: 'Ок',
      en: 'OK',
    }" :showCancel="false" @cancel="closeModal('showInfoModal')">
      <template slot="body">
        <div>{{ tech.info_modal[language] }}</div>
      </template>
    </Modal>
    <Modal :title="{
      ru: 'Удаление группы поверхностей',
      en: 'Удаление группы поверхностей',
    }" :show="showDeleteModal"  @ok="deleteGroup(deleteId)" :okText="{
      ru: 'Удалить',
      en: 'Delete',
    }" @cancel="closeModal('showDeleteModal')">
      <template slot="body">
        <div>{{ tech.delete_modal[language] }}</div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { query } from '@/utilites/axios';
import tech from '@/assets/text/tech.json';

const Select = () => import('@/components/select/I5Select.vue');
const Info = () => import('@/assets/pictures/tech/info.svg');
const Plus = () => import('@/assets/pictures/tech/plus.svg');
const Close = () => import('@/assets/pictures/tech/close.svg');
const Modal = () => import('@/components/modal/Modal.vue');

export default {
  name: 'Roughness',
  data() {
    return {
      tech,
      showInfoAllModal: false,
      showInfoModal: false,
      showDeleteModal: false,
      deleteId: null,
      loading: false,
      activeId: null,
      activeRoughnessType: 'ra',
      allRaRoughnessValue: '12.5',
      allRzRoughnessValue: '50',
      allRaRoughnessId: 10,
      allRzRoughnessId: 16,
      allRoughnessColor: null,
      raValues: [],
      rzValues: [],
      roughnessSurfaces: [],
      activeAdd: true,
      roughnessGroups: {},
      groups: [],
      allRoughnessEdit: true,
      roughnessScale: {},
      showPlus: false,
      unsubscribe: () => {},
    };
  },
  watch: {
    activeId: function (newVal, oldVal) {
      const index = this.groups.findIndex((item) => item.id === String(oldVal));
      if (oldVal !== null && oldVal && index !== -1) {
        this.$store.commit('tech/changeRoughnessForSurface', {
          id: oldVal,
          data: {
            edit: false,
          },
        });
        if (index !== -1) {
          this.groups[index].edit = false;
        }
      }
    },
  },
  computed: {
    activeIndex() {
      return this.$store.state.tech.activeIndex;
    },
    partId() {
      return this.$store.state.tech.partId;
    },
    language() {
      return this.$store.state.index.language;
    },
  },
  methods: {
    openModal(modalName) {
      this[modalName] = true;
    },
    openDeleteModal(id) {
      this.deleteId = id;
      this.showDeleteModal = true;
    },
    closeModal(modalName) {
      this[modalName] = false;
    },
    setRoughnessType(item) {
      if (item.title.toLowerCase() === 'ra') {
        this.allRaRoughnessValue = '12.5';
      } else {
        this.allRzRoughnessValue = '50';
      }
      this.activeRoughnessType = item.title.toLowerCase();
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    setActive(id) {
      if (this.roughnessGroups[id].edit) {
        return true;
      } else {
        return false;
      }
    },
    saveAllRoughness() {
      this.allRoughnessEdit = false;
      this.showPlus = true;
      this.saveData();
      this.setRoughnessScale();
    },
    saveRoughness(id) {
      const index = this.groups.findIndex((item) => item.id === id);
      this.groups[index].edit = false;
      this.activeId = null;
      this.$store.commit('tech/setActiveIndex', [0]);
      this.saveData();
      this.setRoughnessScale();
    },
    setSurfaceRoughness(data, id) {
      const index = this.groups.findIndex((item) => item.id === id);
      this.$store.commit('tech/changeRoughnessForSurface', {
        id,
        data: {
          value: data.short_name,
          roughness_id: data.id,
        },
      });
      this.groups[index].value = data.short_name;
      this.groups[index].roughness_id = data.id;
    },
    saveData() {
      const setType = (type) => {
        if (type === 'ra') {
          return 0;
        } else {
          return 1;
        }
      };
      const groups = [];
      const surfacesData = this.$store.state.tech.surfacesRoughness;
      const surfaces = this.$store.state.model.groups;
      Object.keys(surfacesData).forEach((item) => {
        groups.push({
          roughness_type: setType(surfacesData[item].type),
          roughness_id: surfacesData[item].roughness_id,
          surfaces: surfaces[+surfacesData[item].id].surfaces,
        });
      });
      const roughness = {
        all: {
          roughness_type: setType(this.activeRoughnessType),
          roughness_id: this.activeRoughnessType === 'ra'
            ? this.allRaRoughnessId : this.allRzRoughnessId,
        },
        groups,
      };
      query('put', 'putRoughnessByPart', {
        hash: this.partId.hash,
        time_stamp: this.partId.time_stamp,
        file_name: this.partId.file_name,
        roughness,
      }, {});
    },
    setSurfaceRoughnessType(data, id) {
      const index = this.groups.findIndex((item) => item.id === id);
      this.$store.commit('tech/changeRoughnessForSurface', {
        id,
        data: {
          type: data.title.toLowerCase(),
        },
      });
      this.groups[index].type = data.title.toLowerCase();
      this.groups[index].value = '';
    },
    changeRoughnessGroup(id) {
      const index = this.groups.findIndex((item) => item.id === id);
      this.groups[index].edit = true;
      this.$store.commit('tech/changeRoughnessForSurface', {
        id,
        data: {
          edit: true,
        },
      });
      this.activeId = id;
      this.$store.commit('tech/setActiveIndex', [Number(id)]);
      this.$store.state.model.clearFunction();
      this.$store.state.model.colorGroupFunction([Number(id)]);
    },
    setAllRoughnessValue(data, type) {
      if (type === 'ra') {
        this.allRaRoughnessValue = data.short_name;
        this.allRaRoughnessId = data.id;
      } else {
        this.allRzRoughnessValue = data.short_name;
        this.allRzRoughnessId = data.id;
      }
    },
    addRoughness() {
      if (this.activeAdd) {
        const groups = this.$store.state.model.groupsArray;
        let index = 1;
        if (groups.length === 0) {
          index = 1;
        } else {
          index = Number(groups[groups.length - 1].id) + 1;
        }
        this.activeId = index;
        this.$store.commit('tech/setActiveIndex', [index]);
        this.$store.state.model.clearFunction();
        // this.$store.commit('tech/setSelectType', 'multiple');
        this.activeAdd = false;
      }
    },
    cancelAddRoughness() {
      this.$store.commit('tech/setActiveIndex', [0]);
      this.activeId = null;
      this.activeAdd = true;
      this.setRoughnessScale();
    },
    deleteGroup(id) {
      this.$store.state.model.deleteFunction(id);
      this.$store.commit('tech/delRoughnessForSurface', id);
      this.activeId = null;
      this.setRoughnessScale();
      this.saveData();
    },
    setColor(percent) {
      const zero = 0.5;
      const one = 0.89;
      const change = one - zero;
      const summ = one + zero;
      const color = {
        r: 0,
        g: 0,
        b: 0,
      };
      if (percent >= 0 && percent <= 0.25) {
        color.r = zero;
        color.g = +(zero + ((percent / 0.25) * change)).toFixed(3);
        color.b = one;
      } else if (percent > 0.25 && percent <= 0.5) {
        color.r = zero;
        color.g = one;
        color.b = +(summ - (zero + ((percent / 0.5) * change))).toFixed(3);
      } else if (percent > 0.5 && percent <= 0.75) {
        color.r = +(zero + ((percent / 0.75) * change)).toFixed(3);
        color.g = one;
        color.b = zero;
      } else {
        color.r = one;
        color.g = +(summ - (zero + ((percent) * change))).toFixed(3);
        color.b = zero;
      }
      return color;
    },
    setRoughnessScale() {
      const values = [];
      const result = [];
      const groups = [];
      Object.keys(this.$store.state.tech.surfacesRoughness).forEach((item) => {
        groups.push(this.$store.state.tech.surfacesRoughness[item]);
      });
      if (this.activeRoughnessType === 'ra') {
        values.push({
          value: this.allRaRoughnessValue,
          name: 'all',
        });
      } else {
        values.push({
          value: this.allRzRoughnessValue,
          name: 'all',
        });
      }
      groups.forEach((item) => {
        if (item.value) {
          values.push({
            value: item.value,
            name: item.id,
          });
        }
      });
      values.sort((a, b) => b.value - a.value);
      values.forEach((value, index) => {
        let percent = 0;
        if (values.length > 1) {
          percent = (1 / (values.length - 1)) * index;
        }
        const color = this.setColor(percent);
        result.push(color);
        this.$store.commit('tech/changeRoughnessForSurface', {
          id: value.name,
          data: {
            color: color,
          },
        });
      });
      this.$store.state.model.colorizeFunction({
        groups: values,
        colors: result,
      });
      this.$store.state.model.setScaleFunction({
        roughnessType: this.activeRoughnessType,
        groups: values,
        colors: result,
      });
    },
  },
  components: {
    Info,
    Select,
    Plus,
    Close,
    Modal,
  },
  mounted() {
    this.$store.watch((state) => state.tech.roughnessRzValues, () => {
      this.rzValues = this.$store.state.tech.roughnessRzValues;
    });
    this.$store.watch((state) => state.tech.roughnessRaValues, () => {
      this.raValues = this.$store.state.tech.roughnessRaValues;
    });
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if (mutation.type === 'model/setDefaultGroupState') {
        if (mutation.payload) {
          this.setRoughnessScale();
        }
      }
      if (mutation.type === 'model/setGroupsArray') {
        const result = [];
        mutation.payload.forEach((item) => {
          if (item.length !== 0) {
            if (!this.$store.state.tech.surfacesRoughness[item.id]) {
              this.$store.commit('tech/addRoughnessForSurface', {
                id: item.id,
                type: this.activeRoughnessType,
                value: '',
                edit: true,
                roughness_id: null,
                color: null,
              });
            }
            const cardData = this.$store.state.tech.surfacesRoughness;
            result.push({
              ...{
                type: cardData[item.id].type,
                value: cardData[item.id].value,
                edit: cardData[item.id].edit,
                roughness_id: cardData[item.id].roughness_id,
                color: cardData[item.id].color,
              },
              ...item,
            });
          } else {
            this.$store.state.model.deleteFunction(item.id);
            this.$store.commit('tech/delRoughnessForSurface', item.id);
          }
        });
        this.groups = result;
        this.activeAdd = true;
      } else if (mutation.type === 'tech/changeRoughnessForSurface') {
        // const data = mutation.payload;
        // const index = this.groups.findIndex((index) => +index.id === mutation.payload.id);
        // console.log(data, index);
      }
    });
  },
  created() {
    this.loading = false;
    const roughnessRa = new Promise((resolve) => {
      this.$store.dispatch('tech/getRoughnessRa').then(() => {
        resolve();
      });
    });
    const roughnessRz = new Promise((resolve) => {
      this.$store.dispatch('tech/getRoughnessRz').then(() => {
        resolve();
      });
    });
    Promise.all([roughnessRa, roughnessRz]).then(() => {
      this.$store.dispatch('tech/getRoughness', {
        hash: this.partId.hash,
        time_stamp: this.partId.time_stamp,
        file_name: this.partId.file_name,
      }).then(() => {
        const data = this.$store.state.tech.defaultPartData;
        if (data && data.roughness && data.roughness.all) {
          if (data.roughness.all.roughness_type === 0) {
            let allRoughness = null;
            allRoughness = this.$store.state.tech.roughnessRaValues
              .find((item) => item.id === data.roughness.all.roughness_id);
            this.allRaRoughnessValue = allRoughness.short_name;
            this.allRaRoughnessId = data.roughness.all.roughness_id;
          } else {
            let allRoughness = null;
            allRoughness = this.$store.state.tech.roughnessRzValues
              .find((item) => item.id === data.roughness.all.roughness_id);
            this.allRzRoughnessValue = allRoughness.short_name;
            this.allRzRoughnessId = data.roughness.all.roughness_id;
          }
          this.allRoughnessEdit = false;
        }
        if (data && data.roughness && data.roughness.groups) {
          const result = {};
          data.roughness.groups.forEach((item, index) => {
            let value = '';
            if (item.roughness_type === 0) {
              value = this.$store.state.tech.roughnessRaValues
                .find((i) => i.id === item.roughness_id);
            } else {
              value = this.$store.state.tech.roughnessRzValues
                .find((i) => i.id === item.roughness_id);
            }
            result[index + 1] = {
              id: index + 1,
              type: item.roughness_type === 0 ? 'ra' : 'rz',
              edit: false,
              color: null,
              value: value.short_name,
              roughness_id: item.roughness_id,
            };
          });
          this.$store.commit('tech/setRoughnessForSurface', result);
          this.$store.commit('tech/setDefaultGroups', data.roughness.groups);
        }
        this.loading = true;
      });
    });
  },
  beforeDestroy() {
    this.$store.commit('tech/setActiveIndex', [0]);
    this.$store.state.model.clearFunction();
    this.$store.state.model.clearScaleFunction();
    this.unsubscribe();
    this.$store.commit('model/setDefaultGroupState', false);
  },
};
</script>
